<script>
export default {
  name: "TestimonialsComponent",
  data() {
    return {
      currentIndex: 0,
      videosPerPage: 3,
      videos: [
        { id: "-iY-15mtImo", testimonial: "Testimonio 1" },
        { id: "OiF3vufsWEw", testimonial: "Testimonio 2" },
        { id: "nhofNHi0Zhw", testimonial: "Testimonio 3" },
        { id: "bKDdnfkv6OA", testimonial: "Testimonio 4" },
        { id: "TC5zmYmXE_Q", testimonial: "Testimonio 5" },
      ],
    };
  },

  computed: {
    maxIndex() {
      return this.videos.length - this.videosPerPage;
    },
  },
  methods: {
    nextSlide() {
      if (this.currentIndex < this.maxIndex) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0;
      }
    },
    prevSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        this.currentIndex = this.maxIndex;
      }
    },
  },
};
</script>

<template>
  <section class="bg-slate-100 py-4" id="testimonials">
    <div class="max-w-6xl px-8 mx-auto">
      <h2 data-aos="fade-up" class="relative text-center text-4xl font-bold pb-5">Qué dicen quienes ya lo han probado</h2>
      <p data-aos="fade-up" class="relative text-center text-lg pb-2">
        El verdadero poder de mi método "3 de 3" no solo se refleja en los espacios que ayudo a transformar, sino también en las vidas en las que impacta.
        A lo largo de mis 30 años de recorrido, he tenido el privilegio de acompañar a personas en su camino hacia la creación de hogares que reflejan su esencia, mejoran su bienestar y traen armonía a su día a día.      </p>
      <p class="relative text-center text-lg pb-2 mt-5 mb-3">
        Estos testimonios son ejemplos reales de cómo la organización y armonización del espacio puede cambiar tu vida:
      </p>
    </div>

    <div class="relative w-full overflow-hidden">
      <!-- Carrusel de videos -->
      <div class="flex transition-transform duration-500" :style="{ transform: `translateX(-${currentIndex * (100 / videosPerPage)}%)` }">
        <div v-for="(video, index) in videos" :key="index" class="w-1/3 flex-shrink-0 p-2">
          <div @click="openModal(video.id)" class="cursor-pointer">
            <iframe
              class="w-full h-64"
              :src="`https://www.youtube.com/embed/${video.id}`"
              frameborder="0"
              allow="autoplay; encrypted-media"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
      <!-- Controles -->
      <button @click="prevSlide" class="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full">
        &#10094;
      </button>
      <button @click="nextSlide" class="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full">
        &#10095;
      </button>
    </div>
  </section>
</template>
